import axios from 'axios'
import Vue from 'vue'
import router from '../router'



Vue.prototype.axios = axios

const request = axios.create({
  withCredentials: true,
  baseURL: '/api/v1/web',
  setTimeout: 5000,
})

//请求拦截器：在发送请求前，拦截器可以检测到，在这里可以对数据进行请求前的处理
request.interceptors.request.use((config) => {
  //config：配置对象，里边有重要的属性，请求头headers
   let tokens = JSON.parse(localStorage.getItem('userToken'))
  //  console.log("header==="+tokens)
   config.headers['Authorization'] = tokens
  return config
})

//响应拦截器
request.interceptors.response.use(
  (res) => {
    //成功的回调函数：服务器响应回来的数据，在这里可以先做处理 50:无效token 51:过期的token  52:缺少token
    if(res.data.code==50||res.data.code==51||res.data.code==52){
      //console.log(router.currentRoute.path)
      const currentPath = router.currentRoute.path;
      if(currentPath!=='/login'&&currentPath!=='/register'){
        router.push('/login')
        return;
      }
    }
    return res
  },
  (error) => {
    return Promise.reject(new Error('faile'))
  }
)

export default request
