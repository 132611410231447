<template>
  <div class="container">
    <div class="footer">
        <img class="logo" src="../../../public/images/home/logo.png" alt="logo">
        <div class="footer-info">
            <div class="about">
                <span class="about-title">关于我们</span>
                <span class="about-desc">{{homeSettings.bottomAboutUs}}</span>
            </div>
            <div class="contact">
                <span class="contact-title">联系我们</span>
                <span class="contact-item">{{homeSettings.bottomContactUsOne}}</span>
                <span class="contact-item">{{homeSettings.bottomContactUsTwo}}</span>
                <span class="contact-item">{{homeSettings.bottomContactUsThree}}</span>
                <span class="contact-item">{{homeSettings.bottomContactUsFour}}</span>
            </div>
            <div class="code">
                <span class="code-title">扫码入群</span>
                <img :src="homeSettings.bottomGroupQrcodeUrl" alt="扫码入群">
            </div>
        </div>

        <div class="splice"></div>
        <div class="beian">
            <span>Copyright © 2019-现在 <a target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/">蜀ICP备2023007552号</a></span>
            &nbsp;&nbsp;&nbsp;&nbsp;<span>ICP证 川B2-20241109</span>
            &nbsp;&nbsp;&nbsp;&nbsp;<div class="wang-an-bei-icon"><img width="16px" height="16px" src="/images/home/wang_an_bei.jpg"></div><span><a href="https://beian.mps.gov.cn/#/query/webSearch?code=51010702043373" rel="noreferrer" target="_blank">川公网安备51010702043373号</a></span>
        </div>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name:"HomeFooter",

    computed:{
        ...mapState({
            homeSettings:state=>state.home.homeSettings
        })
    }

}
</script>

<style lang="less" scoped>
.container{
    margin-top: 30px;
    width: calc(100vw);
    min-width: 1440px;
    height: 324px;
    background: #FFFFFF;

    .footer{
        margin: 0 auto;
        width: 1200px;
        height: 324px;
        background: #FFFFFF;

        .logo{
            width: 175px;
            height: 60px;
            margin-top: 30px;
        }
        .footer-info{
            display: flex;
            margin-top: 30px;
            height: 155px;
            .about{

                display: flex;
                flex-direction: column;
                width: 420px;
                
                .about-title{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #000000;
                }
                .about-desc{
                    margin-top: 20px;
                    width: 420px;
                    height: 196px;
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #3A3A3A;
                }
            }

            .contact{
                width: 385px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                flex-direction: column;
                margin-left: 104px;
                .contact-title{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 20px;
                }
                .contact-item{
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #3A3A3A;
                }
            }

            .code{
                    display: flex;
                    flex-direction: column;
                    margin-left: 90px;

                    .code-title{
                        margin-left: 20px;
                        font-size: 16px;
                        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                        font-weight: bold;
                        color: #000000;
                    }
                    img{
                        margin-top: 14px;
                        width: 110px;
                        height: 110px;
                    }
                }
            
        }
        .splice{
            width: 1200px;
            height: 1px;
            background: #EDEDED;
        }

        .beian{
            width: 1200px;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                font-size: 12px;
                font-family: MicrosoftYaHei;
                color: #888888;
                a{
                    text-decoration:none;
                    color:#888888;
                }
            }
            .wang-an-bei-icon{
                width: 20px;
                height: 26px;
            }
        }

    }
}
</style>