import Home from '../../views/Home.vue'
import buyListRoute from '../buyList';
import chatRoute from '../chat'

const baseRoutes = [
    {
        path: '/',
        component:() => import(/* webpackChunkName: "home" */ '../../views/Home.vue'),
        children:[
            // {
            //     path:"/",
            //     redirect:"/home"
            // },
            {
                path:"/",
                component:() => import(/* webpackChunkName: "content" */ '../../views/home/content.vue')
            },
            {
                path:"/publishProduct",
                component:() => import(/* webpackChunkName: "publishProduct" */ '../../views/publishProduct/index.vue')
            },
            {
                name:"woyaomai",
                path:"/buyList",
                component:() => import(/* webpackChunkName: "buyList" */ '../../views/buyList/index.vue'),
                props:({params})=>{
                    return {gameItem:params.gameItem}
                }
            },
            {
                path:"/guarantee",
                component:() => import(/* webpackChunkName: "guarantee" */ '../../views/guarantee/index.vue')
            },
            {
                path:"/businessIntroduction",
                component:() => import(/* webpackChunkName: "businessIntroduction" */ '../../views/businessIntroduction/index.vue')
            },
            {
                path:"/helpCenter",
                component:() => import(/* webpackChunkName: "helpCenter" */ '../../views/helpCenter/index.vue')
            },
            {
                path:"/gamesNews",
                component:() => import(/* webpackChunkName: "gamesNews" */ '../../views/gamesNews/index.vue')
            },
            {
                path:"/myCenter",
                component:() => import(/* webpackChunkName: "myCenter" */ '../../views/myCenter/index.vue'),
                children:[
                    {
                        path:"myProduct",
                        component:() => import(/* webpackChunkName: "myProduct" */ '../../views/myCenter/components/my-product.vue'),
                    },
                    {
                        path:"myOrder",
                        component:() => import(/* webpackChunkName: "myOrder" */ '../../views/myCenter/components/my-order.vue'),
                    },
                    {
                        path:"myCollect",
                        component:() => import(/* webpackChunkName: "myCollect" */ '../../views/myCenter/components/my-collect.vue'),
                    },
                    {
                        path:"myAccountSetting",
                        component:() => import(/* webpackChunkName: "myAccountSetting" */ '../../views/myCenter/components/my-account-setting.vue'),
                        children:[
                            {
                                path:"myProfile",
                                component:() => import(/* webpackChunkName: "myProfile" */ '../../views/myCenter/components/my-account-setting-profile.vue'),
                            },
                            {
                                path:"realNameAuthentication",
                                component:() => import(/* webpackChunkName: "realNameAuthentication" */ '../../views/myCenter/components/my-account-setting-authentication.vue'),
                            },
                            {
                                path:"passwordModify",
                                component:() => import(/* webpackChunkName: "passwordModify" */ '../../views/myCenter/components/my-account-setting-password-modify.vue'),
                            },
                        ]
                    },
                    {
                        path:"myWithdraw",
                        component:() => import(/* webpackChunkName: "myWithdraw" */ '../../views/myCenter/components/my-withdraw.vue'),
                    }
                ]
            },
            {
                path:"/downloadApp", 
                component:() => import(/* webpackChunkName: "downloadApp" */ '../../views/downloadApp/index.vue')
            }
        ]
        
    },
    { 
        path:'/login',
        component:() => import(/* webpackChunkName: "login" */ '../../views/login/index.vue')
    },
    {
        path:'/register',
        component:() => import(/* webpackChunkName: "register" */ '@/views/register/index.vue')
    },
    {

        path:'/error',
        component:() => import(/* webpackChunkName: "404" */ '@/views/error/error.vue')
    },
    chatRoute

]

export default baseRoutes;