<template>
  <div class="home">
    <new-header></new-header>
    <!-- <Header/> -->
    <router-view></router-view>
    <Footer v-if="currentGlobalMenuIndex!==8"/>
  </div>
</template>


<script>

import Header from './home/header.vue';
import NewHeader from './home/newHeader.vue';
import Footer from './home/footer.vue';
import {mapState} from 'vuex';
export default {
   name:'Home',
   components:{
    Header,
    Footer,
    NewHeader
   },
   computed:{
    ...mapState({
      currentGlobalMenuIndex:state=>state.home.currentGlobalMenuIndex
    })
   }
}
</script>

<style lang="less" scoped>
.home{
  width: 100%;
  max-width: 1440px;
  background: #F5F5F5;
  // margin: 0 auto;
}
</style>