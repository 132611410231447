import { getUserProfile } from "../../services/user";


const state = {
    isWithdrawMenu:false,
    userToken:JSON.parse(localStorage.getItem('userToken'))||'',
    myCenterMenuIndex:0,
    userProfile:JSON.parse(localStorage.getItem('userProfile'))||''
 }
 const mutations = {
    // 个人中心设置基本信息是否显示余额卡
    setIsWithdrawMenu(state,payload){
        //console.log('当前payload是：'+payload)
        state.isWithdrawMenu = payload;
    },
    //保存用户token
    saveUserToken(state,payload){
      state.userToken = payload.token;
      localStorage.setItem('userToken',JSON.stringify(payload.token))
   },
   //个人中心菜单
   setMyCenterMenuIndex(state,payload){
      state.myCenterMenuIndex = payload;
   },

   //保存用户资料
   setUserProfile(state,payload){
      state.userProfile=payload;
      localStorage.setItem('userProfile',JSON.stringify(payload))
   },
   //用户退出操作
   clearUserProfile(state,payload){
      state.userToken='',
      state.userProfile=''
   }

 }
 
 const actions = {
    async loadUserProfile(context,payload){
      try{
         const {data} = await getUserProfile();
         if(data.code==200){
            context.commit('setUserProfile',data.data)
            return Promise.resolve(200)
         }else{
            return Promise.reject('获取数据失败')
         }
      }catch(err){}
    }
 }
 
 const getters = {
 
 }
 
 export default {
    namespaced:true,
    state,
    mutations,
    actions,
    getters
 }